
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine, getTestPort } from "@/utils/common";
import { Pageniation, Loading } from "@/utils/decorator";
import { formDownload } from "@/utils/tools";
import t from "@common/src/i18n/t";

import dayjs from "dayjs";
const management = namespace("management");
const base = namespace("base");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class TenantList extends Mixins(tableCheck) {
  @management.Action queryTmallChangeList;
  @management.Action forbidTenant;
  @management.Action enableTenant;
  @management.Action updateTmallCheck;
  @management.Action updateSignupStatus;
  @management.Action getViewContractUrl;
  @management.Action applyResign;
  @management.Action cancelSign;

  @base.Action getDictionaryList;
  showTmallCheck: boolean = false;
  showRevokeSign: boolean = false;
  checkValue: string = "1";
  noPassReason: any = t("v210831.repeat-store");
  dayjs = dayjs;
  searchForm = {
    shopK2Name: "",
    contactsName: "",
    contactsPhone: "",
    tmallApplyStatus: "",
    startApplyTime: "",
    endApplyTime: "",
    signedStatus: "",
    startSignedTime: "",
    endSignedTime: ""
  };
  revokeSignReason: string = ""; // 撤销签约原因
  emptyToLine = emptyToLine;
  tenantList: any[] = [];
  applyStatusList: any[] = [];
  signupList: any = [];
  TmallVerifyStatusList: any = [];
  get searchList() {
    return [
      {
        label: t("v210831.store-name"),
        type: "input",
        value: "",
        prop: "shopK2Name"
      },
      {
        label: t("v210831.change-number"),
        type: "input",
        value: "",
        prop: "signInfoModifyCode"
      },
      {
        label: t("v210831.person-in-charge"),
        type: "input",
        value: "",
        prop: "contactsName"
      },
      {
        label: t("v210831.telephone-number-of"),
        type: "input",
        value: "",
        prop: "contactsPhone"
      }
    ];
  }
  activeRow: any = "";
  handleTmallCheck(row) {
    this.activeRow = row;
    this.showTmallCheck = true;
  }
  handleCheckResultConfirm() {
    this.updateTmallCheck({
      applyCode: this.activeRow.applyCode,
      tmallVerifyResult: this.checkValue,
      tmallVerifyMessage: this.noPassReason
    }).then(data => {
      this.$message.success(t("setting.operate-successfully"));
      this.showTmallCheck = false;
    });
  }
  handleViewContract(row) {
    this.getViewContractUrl({
      applyCode: row.applyCode
    }).then(data => {
      // console.log(data);
      window.open(data.data);
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    console.log(data);
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "applyTimeRange") {
          this.searchForm.startApplyTime = item.value ? item.value[0] : "";
          this.searchForm.endApplyTime = item.value ? item.value[1] : "";
        } else if (item.prop === "signedTime") {
          this.searchForm.startSignedTime = item.value ? item.value[0] : "";
          this.searchForm.endSignedTime = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  handleExportData() {
    formDownload(`${getTestPort()}/storeApply/export`, this.searchForm);
  }
  handleReSign(row) {
    this.applyResign({
      applyCode: row.applyCode
    }).then(data => {
      this.$message.success(t("v210831.successful-execution"));
      this.loadListData();
    });
  }
  handleCancelSign(row) {
    this.revokeSignReason = "";
    this.activeRow = row;
    this.showRevokeSign = true;
  }
  handleRevokeSign() {
    this.cancelSign({
      applyCode: this.activeRow.applyCode,
      revokeReason: this.revokeSignReason
    }).then(data => {
      this.$message.success(t("v210831.the-contract-has"));
      this.showRevokeSign = false;
    });
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.queryTmallChangeList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.tenantList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    // 审核状态字典
    this.getDictionaryList("apply_status").then(data => {
      this.applyStatusList = data;
    });
    this.getDictionaryList("signed_status").then(data => {
      this.signupList = data;
    });
    this.getDictionaryList("tmall_verify_result").then(data => {
      this.TmallVerifyStatusList = data;
    });
  }
  handleLinkOpen(row) {
    this.$router.push(`/management/store-open?code=${row.applyCode}`);
  }
  handleLinkView(row) {
    window.open(this.$router.resolve({ path: `/management/tmall/change-diff/${row.signInfoModifyCode}` }).href);
  }
}
